// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-get-love-thanks-jsx": () => import("../src/pages/get-love-thanks.jsx" /* webpackChunkName: "component---src-pages-get-love-thanks-jsx" */),
  "component---src-pages-get-love-jsx": () => import("../src/pages/get-love.jsx" /* webpackChunkName: "component---src-pages-get-love-jsx" */),
  "component---src-pages-give-love-thanks-jsx": () => import("../src/pages/give-love-thanks.jsx" /* webpackChunkName: "component---src-pages-give-love-thanks-jsx" */),
  "component---src-pages-give-love-jsx": () => import("../src/pages/give-love.jsx" /* webpackChunkName: "component---src-pages-give-love-jsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

